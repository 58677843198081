import { graphql } from "gatsby"
import React from "react"
import Layout from "../src/components/layout"
import ImageCover from "../src/components/ImageCover"
import RegionGrid from "../src/components/RegionGrid/RegionGrid"
import Seo from "../src/components/seo"

export default function regionTemplate({ pageContext, data }) {
  //console.log(data, pageContext)
  const domaines = data.allStrapiDomaines.nodes.map(e => ({
    title: e.Domaine,
    slug: e.Slug,
    image: e.MainImage?.localFile?.childImageSharp.gatsbyImageData,
  }))

  domaines.sort(function (a, b) {
    if (a.title < b.title) {
      return -1
    }
    if (a.title > b.title) {
      return 1
    }
    return 0
  })
  const headingData =
    data.allStrapiDomaines.nodes[0]?.region.region.toUpperCase()

  const image =
    data.strapiRegions.Image.localFile.childImageSharp.gatsbyImageData

  return (
    <Layout relative={false}>
      <Seo
        title={
          headingData?.toLowerCase().charAt(0).toUpperCase() +
          headingData?.toLowerCase().slice(1) +
          " " +
          "Growers"
        }
      />
      <ImageCover
        image={image}
        heading={headingData}
        section={"#wines"}
        third
        truedat={true}
      />
      <RegionGrid bigData={domaines} />
    </Layout>
  )
}

export const query = graphql`
  query ($id: Int!, $stringId: String!) {
    allStrapiDomaines(filter: { region: { id: { eq: $id } } }) {
      nodes {
        Domaine
        Slug
        region {
          region
        }
        Country
        MainImage {
          localFile {
            childImageSharp {
              gatsbyImageData(height: 300, quality: 60)
            }
          }
        }
      }
    }
    strapiRegions(id: { eq: $stringId }) {
      Image {
        localFile {
          childImageSharp {
            gatsbyImageData(width: 1800, quality: 60)
          }
        }
      }
    }
  }
`
